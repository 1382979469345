*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

#root{
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

.loader{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

body{
  font-family: 'Raleway', sans-serif;
  background: linear-gradient(90deg, #F7EAE4, #DCDEF1);
  color: #230B59;
  min-height: 100vh;
}

b{
  font-weight: bolder;
  color: #4840BB;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track {
  background: #274656;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb {
  background: #01111a;
  border-radius: 100px;
}

/* Background */
.bg{
  position:fixed;
  z-index: -1;
  bottom: 0px;  
}

.bgtwo{
  position:fixed;
  z-index: -1;
  top: 220px;
}

.bgtemp{
  position:fixed;
  z-index: -1;
  top: -30px;
}


/* NAVBAR */

.Navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  position: fixed;
  width: 100%;
  z-index: 2;

  height: 70px;
  top: 0;
  transition: all 0.7s;
}

.blur{
  background: linear-gradient(90deg, #F7EAE4, #DCDEF1);
}

.Logo{
  font-size: 18px;
  padding-left: 3%;
  color: #230B59;
  display: flex;
  align-items: center;
  text-align: center;
  cursor:pointer;
}

.Logo a{
  color: #230B59;
  display:flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 95%;
  padding: 2px 7px;
  text-decoration: none;
}
.Logo a:hover{
  color: #4840BB;
}
.Logo a::after{
  background-color: #4840BB;
  content: "";
  position: absolute;
  height: 3px;
  left: 5px;
  bottom: 0;
  width: 0px;
  border-radius: 2px;
  transition: 0.3s;
}
.Logo a:hover:after{
  width: 94%;
}

.NavbarLinks{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3%;
  font-weight: 501;
  font-size: 18px;
}

.NavbarLinks li{
  padding: 2px 20px;
  list-style-type: none;
}

.NavbarLinks a{
  color: #230B59;
  display:flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 2px 7px;
  text-decoration: none;
  cursor: pointer;
}
.NavbarLinks a:hover{
  color: #4840BB;
}
.NavbarLinks a::after{
  background-color: #4840BB;
  content: "";
  position: absolute;
  height: 3px;
  left: 2px;
  bottom: 0;
  width: 0px;
  border-radius: 2px;
  transition: 0.3s;
}
.NavbarLinks a:hover:after{
  width: 83%;
}

.Hamburger{
  display: none;
}

@media(max-width:800px){
  .Logo{
    padding-left: 6%
  }
  
  .Navbar{
    width: 100%;
  }

  .NavbarLinks{
    font-size: 26px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    inset: 0 0 0 50%;
    gap: 50px;
    height: 100vh;
    background: linear-gradient(90deg, #F7EAE4, #DCDEF1);
    padding-top: max(20%,120px);
    transform: translateX(100%);
    transition: 0.5s;
  }

  .showNavbar{
    transform: translateX(0);    
  }

  .Hamburger{
    display: block;
    z-index: 2;
    padding: 5px;
    padding-right: 7%;
    cursor: pointer;
  }

  .bar{
    display: block;
    background-color: #230B59;
    border-radius: 50px;
    height: 2.5px;
    width: 30px;
    margin: 6px;
    transition: 0.5s;
  }
  .barOne{
    rotate: -225deg;
    position: relative;
    top: 4px;
    transition: 0.5s ;
  }
  .barTwo{
    display: none;
  }
  .barThree{
    position: relative;
    bottom: 4px;
    rotate: 225deg;
    transition: 0.5s;
  }
  
}


/* HOME PAGE */

.HomePage{
  display: flex;
  justify-content: space-evenly;
  min-height: 75vh;
  margin-top: 100px;
  margin-bottom: -50px;
  align-items: center;
}
.HomeText h1{
  font-weight: 600;
  font-size: 45px;
  letter-spacing: 0.7px;
  padding-top: 10px;
}

.TypeEffect{
  padding: 25px 0px;
  font-weight: 600;
  font-size: 32px;
  letter-spacing: 0.6px;
}

.illustration{
  width: 400px;
  height: 400px;
}

.headerLinks{
  margin-top: 30px;
  display: flex;
  width: 400px;
  font-size: 45px;
  justify-content: space-evenly;
}

.headerLinks a{ 
  text-decoration: none;
  color: inherit;
}

 /* GitHub */
 .headerLinks a:nth-child(1):hover { 
  color: #333333; 
}

 /* LinkedIn */
.headerLinks a:nth-child(2):hover { 
  color: #0A66C2; 
}

 /* Email */
.headerLinks a:nth-child(3):hover { 
  color: #4840BB;
}

 /* Instagram */
 .headerLinks a:nth-child(4):hover { 
  color: #C13584;
}

@media(max-width:900px){
  .HomePage{
    display: block;
    text-align: center;
    margin-top: 100px;
    padding: 25px;
  }
  .HomeText{
    text-align: left;
    display: inline-block;
  }
  .illustration{
    margin: auto;
    margin-bottom: 50px;
  }
}

@media(max-width: 500px) {
  .HomePage{
    min-height: 70vh;
  }
  .NavbarLinks{
    inset: 0 0 0 30%;
  }
  .HomeText h1{
    font-weight: 500;
    font-size: 28px;
  }  
  .TypeEffect{
    font-weight: 500;
    font-size: 24px;
  }
  .illustration{
    width: 300px;
    height: 300px;
    margin-bottom: 0px;
  }
  .headerLinks {
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    justify-content: center;
    display: flex;
    gap: 40px;
    align-items: center;
  }
}

.AboutPage{
  display: flex;
  justify-content: space-evenly;
  min-height: 80vh;
  margin-top: 100px;
  align-items: center;
  padding: 25px;
  padding-top: 200px;
}

.AboutText{
  width: 50%;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 1.2px;
}
.AboutTextHeading{
  font-size: 42px;
  font-weight: 600;
  padding-bottom: 40px;
}
.AboutText a{
  color: #4840BB;
  font-weight: bold;
}

.Avatar{
  width: 280px;
}

@media(max-width:900px){
  .AboutPage{
    display: block;
    text-align: center;
    padding: 30px;
    padding-top: 100px;  
  }
  .AboutText{
    text-align: left;
    display: inline-block;
    width: 95%;
    line-height: 24px;
    letter-spacing: 0.6px;
    margin-bottom: 80px;
  }
  .AboutTextHeading{
    font-size: 32px;
  }

  .Avatar{
    width: 300px;
    margin-bottom: 75px;
  }
}

/* Project */

.projectHeading{
  text-align: center;
  padding: 75px 0;
  letter-spacing: 0.4px;
  font-size: 36px;
  margin-top: 100px;
}

.project{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25px 5%;
}

.projectBox{
  width: 350px;
  border: 3px solid #431f47;
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  letter-spacing: 0.5px;
  font-size: 18px;
  line-height: 22px;
  
  margin: 0px 25px 50px 25px;
  transition: 0.5s;
}
.projectBox:hover{
  transform: scale(1.05);
  box-shadow: 0 4px 10px 0 #431f47;
}

.projectPhoto{
  display: block;
  width: 100%;
  margin: 20px 0px;
}

.projectbtn{
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  width: 40%;
  margin: 25px 3% 0;
  border-radius: 5px;
  background-color: #431f47;
  color: #E7E7E7;
  height: 30px;
  cursor:pointer;
  text-decoration: none;
  border-style: none;
}


/* Resume */

.ResumePage{
  padding-top: 50px;
  width: fit-content;
  position: relative;
  margin: 100px auto 0px;
  text-align: center;
}

.resumeview{
  pointer-events: none;
}

.downloadCV{
  cursor:pointer;
  margin: 25px 0px;
  width: 200px;
  height: 40px;
  background-color:#431f47;
  color: #E7E7E7;
  text-decoration: none;
  border-radius: 5px;
  border-style: none; 
  transition: all 0.5s;
}

.downloadCV:hover{
  transition: all 0.5s;
  background-color: #E7E7E7;
  color: #431f47;
}


/* FOOTER */

footer{
  width: 90%;
  height: 40px;
  margin: 10px auto;
  margin-top: 80px;
  letter-spacing: 0.5px;
  
  display:flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  
  position: relative;
  bottom: 0px;
  text-align: center;
}

footer h4{
  margin: 0 25px;
  font-weight: 500;
}

.footerLinks{
  display: flex;
  width: 250px;
  font-size: 30px;
  justify-content: space-evenly;
}

.footerLinks a{ 
  text-decoration: none;
  color: inherit;
}

 /* GitHub */
 .footerLinks a:nth-child(1):hover { 
  color: #333333; 
}

 /* LinkedIn */
.footerLinks a:nth-child(2):hover { 
  color: #0A66C2; 
}

 /* Email */
.footerLinks a:nth-child(3):hover { 
  color: #4840BB;
}

 /* Instagram */
 .footerLinks a:nth-child(4):hover { 
  color: #C13584;
}

@media(max-width:750px){
  footer{
    height: 110px;
  }
}
